<template>
  <figure :class="$style.container">
    <a
      :class="[{ ContentTeaserM__videoLink: isVideo }, $style.mediaLink]"
      :href="primaryLink"
      :target="primaryLinkNewWindow ? '_blank' : '_self'"
      aria-label="goto detail"
      @click="
        wtParams &&
          wtTrack(
            {
              cat: wtParams.doctype
            },
            mergeWtParams(
              wtParams,
              {
                filename: primaryLink
              },
              {}
            )
          )
      "
    >
      <div :class="$style.image" :style="{ backgroundImage: `url(${image})` }">
        <img :src="image" alt="" />
      </div>
      <i v-if="isVideo" class="fab fa-youtube" />
    </a>
    <figcaption>
      <a
        v-if="category"
        :class="$style.secondaryLink"
        :href="secondaryLink"
        :target="secondaryLinkNewWindow ? '_blank' : '_self'"
        >{{ category }}
      </a>
      <div :class="$style.primaryLink">
        {{ linkTitle }}
      </div>
      <div v-if="text">
        <div :class="$style.copy">
          {{ text }}
        </div>
        <a
          v-if="!isVideo"
          :class="$style.more"
          :href="primaryLink"
          :target="primaryLinkNewWindow ? '_blank' : '_self'"
          @click="
            wtParams &&
              wtTrack(
                {
                  cat: wtParams.doctype
                },
                mergeWtParams(
                  wtParams,
                  {
                    filename: primaryLink
                  },
                  {}
                )
              )
          "
        >
          {{ dictionaryMore }}
          <i class="fas fa-chevron-right" />
        </a>
      </div>
    </figcaption>
  </figure>
</template>

<script setup>
import { mergeSwitchWtParams } from '../../utils/tracking'

defineProps({
  isVideo: {
    type: Boolean,
    default: false
  },
  primaryLink: {
    type: String,
    default: ''
  },
  primaryLinkNewWindow: {
    type: Boolean,
    default: true
  },
  secondaryLink: {
    type: String,
    default: ''
  },
  secondaryLinkNewWindow: {
    type: Boolean,
    default: true
  },
  category: {
    type: String,
    default: ''
  },
  linkTitle: {
    type: String,
    default: ''
  },
  image: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  wtParams: {
    type: Object,
    default: () => {}
  }
})

const mergeWtParams = (globalWtParams, itemWtParams, itemParams) => {
  return mergeSwitchWtParams(globalWtParams, itemWtParams, itemParams)
}

const wtTrack = window.wtTrack
const dictionaryMore = window.dictionary.more
</script>

<style module lang="scss">
@import './ContentTeaserM.scss';
</style>
