<template>
  <figure :class="$style.container">
    <a
      :class="$style.mediaLink"
      :href="data.primaryLink"
      target="_self"
      @click="
        data.wtParams &&
          wtTrack(
            {
              cat: wtParams.doctype
            },
            mergeWtParams(
              wtParams,
              {
                filename: data.primaryLink
              },
              {}
            )
          )
      "
    >
      <img :src="data.image" :alt="data.name" />
    </a>
    <figcaption>
      <a :class="$style.secondaryLink" :href="data.secondaryLink">{{ data.category }}</a>
      <a
        :class="$style.primaryLink"
        :href="data.primaryLink"
        @click="
          data.wtParams &&
            wtTrack(
              {
                cat: wtParams.doctype
              },
              mergeWtParams(
                wtParams,
                {
                  filename: data.primaryLink
                },
                {}
              )
            )
        "
      >
        <h3>{{ data.name }}</h3>
        <div>{{ data.date }}</div>
        <div><Flag :flag="data.flag" />{{ data.city }}</div>
        <div>{{ data.location }}</div>
      </a>
    </figcaption>
  </figure>
</template>

<script setup>
import Flag from '../Flag/Flag.vue'
import { mergeSwitchWtParams } from '../../utils/tracking'

defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  wtParams: {
    type: Object,
    default: () => {}
  }
})

const wtTrack = window.wtTrack

const mergeWtParams = (globalWtParams, itemWtParams, itemParams) => {
  return mergeSwitchWtParams(globalWtParams, itemWtParams, itemParams)
}
</script>

<style module lang="scss">
@import './EventTeaser.scss';
</style>
