import { createApp } from 'vue'
import App from './App.vue'
import VueTippy from 'vue-tippy'

import initMainMenu from '../MainMenu/main'
import initCountrySwitcher from '../CountrySwitcher/main'
import initLanguageSwitcher from '../LanguageSwitcher/main'
import initMobileMenu from '../MobileMenu/main'
import initQuickLinks from '../QuickLinks/main'
import initSocialLinks from '../SocialLinks/main'
import initSocialSharing from '../SocialSharing/main'
import initFooter from '../Footer/main'
import initHeroSlider from '../HeroSlider/main'
import initTextTeaser from '../TextTeaser/main'
import initSalesContainer from '../SalesContainer/main'
import initDownloadBox from '../DownloadBox/main'
import initProductArea from '../ProductArea/main'
import initProductAreaControlledCarousel from '../ProductAreaControlledCarousel/main'
import initModal from '../Modal/main'
import initWishList from '../WishList/main'
import initMondayWebforms from '../MondayWebforms/main'
import initElasticSearch from '../ElasticSearch/main'
import initNewsletterQuickSubscription from '../NewsletterQuickSubscription/main'
import initCookieDisclaimer from '../CookieDisclaimer/main'
import initGeoIPOverlay from '../GeoIPOverlay/main'
import initCloudWordBox from '../CloudWordBox/main'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

const init = () => {
  const app = createApp({
    render: (h) => h(App)
  })

  app.use(VueTippy)
  app.mount('#app')

  initMainMenu()
  initCountrySwitcher()
  initLanguageSwitcher()
  initMobileMenu()
  initQuickLinks()
  initSocialLinks()
  initSocialSharing()
  initFooter()
  initHeroSlider()
  initTextTeaser()
  initSalesContainer()
  initDownloadBox()
  initProductArea()
  initProductAreaControlledCarousel()
  initModal()
  initWishList()
  initMondayWebforms()
  initElasticSearch()
  initNewsletterQuickSubscription()
  initCookieDisclaimer()
  initGeoIPOverlay()
  initCloudWordBox()
}

if (document.body.classList.contains('cookiebannerEnabled')) {
  window.addEventListener('CookiebotOnLoad', init)
} else {
  init()
}
